class Popup {
    constructor(Modal) {
        this.modal = Modal;
        this.ajaxPopup = true
        //path de l'action editer de fos user : formulaire de modification
        let uri = window.location.pathname;
        let url = '';
        
        if(uri == '/'){
            url = window.location.origin + '/ajax/popup-index';
        }

        else if(uri == '/contact/maitre-olivier-cohen'){
            url = window.location.origin + '/ajax/popup/maitre-olivier-cohen';
            uri = 'contactez-transport-sernam';
        }

        else{
            this.ajaxPopup = false
        }
        
        if(this.ajaxPopup){
            
            $.ajax({
                type: 'get',
                url: url,
                async: false,
            }).done(function(data){
                if( data ){
                    $( 'body' ).append( data );
                }
            });
            
            if($('#myModalPopup').length){

                let myModal = new this.modal($('#myModalPopup'), {
                    keyboard: false
                })

                setTimeout(function() { 
                    myModal.show();
                }, 2000);
            }

            let myModalEl = document.getElementById('myModalPopup')
            
            if(myModalEl){
                myModalEl.addEventListener('hide.bs.modal', function (event) {
                    const d = new Date();
                    const exquarthour = 1;
                    d.setTime(d.getTime() + (exquarthour*15*60*1000));
                    let expires = "expires="+ d.toUTCString();
                    let cname = window.location.pathname;
                    console.log('popup-' + ( uri == '/' ? 'index' : uri.substring(1) ));
                    document.cookie = 'popup-' + ( uri == '/' ? 'index' : uri ) + "=opened;" + expires + ";path=/";
                })
            }
        }
    }

}

export default Popup;