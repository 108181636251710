/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
const $ = require('jquery');
global.$ = global.jQuery = $;

import { Modal, Carousel } from 'bootstrap';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import Alert from 'bootstrap/js/dist/alert';

import 'aos/dist/aos.css';
import './styles/includes/css/cookieconsent.css';
import Lightbox from './styles/includes/js/lightbox';

import { tns } from '../node_modules/tiny-slider/src/tiny-slider';
import '../node_modules/tiny-slider/src/tiny-slider.scss';
//import './styles/includes/js/init-tiny-slider';

let q = get_query();

if (q[3] == 'contact') {
    setTimeout(() => {
        $('.lightbox.imgs a').each(function () {
            $(this).click(function () {
                const lightbox = new Lightbox($(this), Modal, Carousel);
                return false;
            });
        })
    }, 1000);
}


// import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.min.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js';

// $(document).ready(function() {
//     // you may need to change this code if you are not using Bootstrap Datepicker
//     $('.js-datepicker').datepicker({
//         format: 'dd-mm-yyyy'
//     });
// });


import Popup from './styles/includes/js/popup';
const popup = new Popup(Modal)

import './styles/includes/js/stickymenu';

const simpleParallax = require('simple-parallax-js');
let image = document.getElementsByClassName('img-parallax');
new simpleParallax(image, {
    delay: .6,
    transition: 'cubic-bezier(0,0,0,1)',
    scale: 1.5,
    orientation: 'up right'
});

let sliderAccueilQuery = get_query();

if (3 in sliderAccueilQuery && sliderAccueilQuery[3] == '' ) {

    var slider = tns({
        container: '.slider-competences .slider',
        items: 3,
        slideBy: 'page',
        autoplay: true,
        nav: false,
        controls: false,
        autoplayButtonOutput: false,
        responsive: {
            300: {
                edgePadding: 20,
                gutter: 0,
                items: 1
            },
            640: {
                edgePadding: 20,
                gutter: 20,
                items: 2
            },
            700: {
                gutter: 30
            },
            900: {
                gutter: 10,
                items: 4
            }
        }
    });
}

// setTimeout(() => {
//     $('.slider-situation a').each(function () {
//         $(this).click(function () {
//             const lightboxAccueil = new Lightbox($(this), Modal, Carousel, 'slider');
//             return false;
//         });
//     })
// }, 1000);

function get_query() {
    var url = document.location.href;
    var qs = url.split('\/');
    return qs;
}



